import { useMemo, useState } from "react";
import { createStyles, Link, makeStyles } from "@material-ui/core";
import moment from "moment";

import { Pagination, PaginChange } from "../../@type/Pagination";
import { Customer } from "../../@type/Customer";
import ReactTable from "../react-table/ReactTable";
import TableSortingHeader from "../utilities/MainTableComponents/TableSortingHeader";
import CustomerDetailsDialog from "../customer-details-dialog/CustomerDetailsDialog";
import UpdateCustomerDialog from "../update-customer-dialog/UpdateCustomerDialog";
import useCustomerLogin from "../../hooks/useCustomerLogin";

export const useTableStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 131px)", // to fix the pagination
      position: "relative",
    },
    title: {
      margin: 10,
    },
    outer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 10,
    },
    actionsOuter: {
      display: "flex",
      flexDirection: "row",
    },
    btnsOuter: {
      margin: "0 10px",
    },
    toggleBtn: {
      height: 36.44,
      "& .MuiToggleButton-label": {
        fontSize: "smaller",
      },
    },
  })
);

interface ManageCustomersProps {
  customers: Customer[];
  pagination: Pagination;
  setPagination: (value: Pagination) => void;
  isTableLoading: "Loading" | "Failed" | "Success" | "NoItem";
  setPaginChange: (value: PaginChange) => void;
  paginChange: PaginChange;
  sortType: "ASC" | "DESC" | null;
  sortColumn: string;
  setSortColumn: (value: string) => void;
  setSortType: (value: "ASC" | "DESC" | null) => void;
  goToFirstPage: () => void;
  refreshCustomers: () => void;
}

const ManageCustomers = ({
  sortColumn,
  sortType,
  customers,
  pagination,
  paginChange,
  isTableLoading,
  goToFirstPage,
  setPagination,
  setSortType,
  setSortColumn,
  setPaginChange,
  refreshCustomers,
}: ManageCustomersProps) => {
  const classes = useTableStyles();
  const data = useMemo(() => customers, [customers]);
  const { handleLoginClick } = useCustomerLogin();

  const sortClick = (property: string) => {
    setSortColumn(property);
    if (!sortType || property !== sortColumn) {
      setSortType("DESC");
      if (property !== sortColumn) {
        goToFirstPage();
      }
    } else if (sortType === "DESC") {
      setSortType("ASC");
    } else if (sortType === "ASC") {
      setSortType(null);
    }
  };

  const getUserStatus = (status) => {
    switch (status) {
      case 1:
        return "ACTIVE";
      case 2:
        return "INACTIVE";
      case 3:
        return "SUSPENDED";
      default:
        return "ACTIVE";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Account No",
        accessor: "account_no",
        Cell: ({ row }) => (
          <Link onClick={() => handleLoginClick(row.original.account_no)}>
            {row.original.account_no}
          </Link>
        ),
      },
      {
        Header: `Email Address`,
        accessor: "email",
        Cell: ({ row }) => row.original.email,
      },
      {
        Header: `Company`,
        accessor: "address.companyName",
        Cell: ({ row }) => row.original.address.companyName,
      },
      {
        Header: `Postal Code`,
        accessor: "address.postalCode",
        Cell: ({ row }) => row.original.address.postalCode,
      },
      {
        Header: `Phone`,
        accessor: "address.phone",
        Cell: ({ row }) => row.original.address.phone,
      },
      {
        Header: (
          <TableSortingHeader
            header="Created At"
            property="signupDate"
            sortType={sortType}
            sortVal={sortColumn}
            sortClick={sortClick}
          />
        ),
        accessor: "signupDate",
        Cell: ({ row }) =>
          moment(row.original.signupDate).format("YYYY/MM/DD h:mm a"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => getUserStatus(row.original.status),
      },
      {
        Header: "Email Confirmed",
        accessor: "isEmailConfimed",
        Cell: ({ row }) => (row.original.isEmailConfimed ? "Y" : "N"),
      },
      {
        Header: "User Level",
        accessor: "user_level",
        Cell: ({ row }) => row.original.user_level,
      },
      {
        Header: "",
        accessor: "ipAddress",
        Cell: ({ row }) => (
          <>
            <UpdateCustomerDialog
              accountNo={row.original.account_no}
              userAccountLevel={row.original.user_level}
              status={row.original.status}
              shipvistaconnect={row.original.shipvistaconnect}
              refreshCustomers={refreshCustomers}
            />
            <CustomerDetailsDialog customer={row.original} />
          </>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [sortType, sortColumn]
  );

  return (
    <>
      <div className={classes.outer}>
        <div className={classes.actionsOuter}>
          {/* <MyTicketsStatusSelection
                            setStatusArr={setStatusArr}
                            statusArr={statusArr}
                            setPaginChange={setPaginChange}
                        />
                        <GlobalSearch
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch} />
                        <TableDateFilterWrapper
                            dateFilter={dateFilter}
                            setOrderDateFilter={(value) => {
                                setDateFilter(value);
                            }}
                            goToFirstPage={goToFirstPage}
                        /> */}
        </div>
        {/* <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => { setCreateNewTicketDialogOpen(true); }}
                >
                    Add New Ticket
                </Button> */}
      </div>
      <ReactTable
        isTableLoading={isTableLoading}
        pagination={pagination}
        setPagination={setPagination}
        columns={columns}
        data={data}
        setPaginChange={setPaginChange}
        paginChange={paginChange}
        errorMessage="Failed to retrieve customer records"
      />
    </>
  );
};

export default ManageCustomers;
